import {ActionTree} from 'vuex';

import axios from '@/Support/Http/Axios';
import axiosV3 from '@/Support/Http/AxiosV3';
import router from '@/Support/Http/Router';

import Cookies from "js-cookie";

import {AuthState, Company} from '../Types';
import {RootState} from '@/Support/Store/Types/RootState';
import MESSAGE_LEVEL from '@/Domains/Auth/Constants/MessageLevel';

import * as _ from 'lodash';


export const actions: ActionTree<AuthState, RootState> = {

    resetProcessing({ commit }) {
        commit('processingStateChange', false);
    },

    async authenticate({commit, dispatch, state}, {email, password, _tk}) {

        try {
            commit('processingStateChange', true);

            /**
             * Valida os dados informados pelo usuário
             */
            if (_.isEmpty(email) || _.isEmpty(password)) {
                return commit('authenticationError', {
                    level: MESSAGE_LEVEL.WARNING,
                    message: 'Dados preenchidos incorretamente.',
                });
            }

            /**
             * Limpa o estado de erro
             */
            dispatch('clearErrorState');

            const {data}: { data: Company[] } = await axios.post('/login/logar', {
                email,
                password,
                _tk,
            });

            if (!_.isArray(data) || _.isEmpty(data)) {
                return commit('authenticationError', {
                    level: MESSAGE_LEVEL.ERROR,
                    message: 'Erro ao localizar empresas',
                });
            }

            const responseV3 = await axiosV3.post('/auth/login', {
                email,
                password,
            })

            const dataV3 = responseV3.data

            if (dataV3) {
                let domain = 'new.simpllis.app'

                if (window.APP_URL.indexOf('localhost') !== -1) {
                    domain = 'localhost'
                }

                if (window.APP_URL.indexOf('homolog') !== -1) {
                    domain = 'new.homolog.simpllis.app'
                }

                Cookies.set('auth_bearer_token', dataV3.access_token, {
                    expires: dataV3.expires_in / 86400,
                    domain: domain,
                    secure: true,
                })
            }

            return commit('companiesLoaded', _.map(data, (element) => _.extend({}, element, {email})));
        } catch (e) {
            commit('processingStateChange', false);

            if (!e.response) {
                return commit('authenticationError', {
                    level: MESSAGE_LEVEL.ERROR,
                    message: 'Algo inesperado ocorreu. Caso este erro persista, entre em contato conosco.',
                });
            }

            if (e.response.data.message) {
                state.errors = e.response.data.errors;

                return commit('authenticationError', {
                    level: MESSAGE_LEVEL.ERROR,
                    message: e.response.data.message,
                });
            }

            commit('authenticationError', {
                level: MESSAGE_LEVEL.ERROR,
                message: 'Algo inesperado ocorreu. Caso este erro persista, entre em contato conosco.',
            });
        }
    },

    clearErrorState({state}) {
        state.error = {
            level: MESSAGE_LEVEL.NONE,
            message: '',
        };

        state.errors = [];
    },

    validateUser({ state, dispatch }, company: Company) {
        const {id_pessoa, id_empresa, email_valido, email_confirmado, termos, usuario_exclaim, plano} = company;

        if (usuario_exclaim || plano === 'TRIAL') {
            return dispatch('createSession', company);
        }

        if (!email_valido) {
            return router.push({path: `/usuario/email-invalido/${id_empresa}/${id_pessoa}`});
        }

        if (!email_confirmado) {
            return router.push({path: `/usuario/confirmacao-pendente/${id_empresa}/${id_pessoa}`});
        }

        const { EULA, PRIVACY, COOKIE } = termos;

        if (!EULA || !PRIVACY || !COOKIE) {
            return router.push({path: `/usuario/termos/${id_empresa}/${id_pessoa}`});
        }

        return dispatch('createSession', company);
    },

    async acceptAgreement({ commit, state }, { term, company }) {

        const location = state.location || {};

        const tipo = term.type.toLowerCase();

        await axios.post(`${window.__JURIDICAL_API}/${tipo}`,
            {
                uuid: term.auth,
                id_empresa: company.id_empresa,
                id_usuario: company.id_pessoa,
                nome_usuario: company.nome_pessoa,
                cpf_cnpj: company.cpf_cnpj,
                email: company.email,
                longitude: location.longitude || null,
                latitude: location.latitude || null,
                tipo: term.type.toLowerCase(),
                data_hora_registro: term.accepted_at,
            },
            {
                headers: {
                    Authorization: `Bearer ${window.__JURIDICAL_API_KEY}`,
                },
            },
        );

        return commit('acceptAgreement', {
            term,
            company,
        });
    },

    async createSession({ commit, state }, company: Company) {
        try {

            const location = state.location || {};

            const {data} = await axios.post('/login/autenticarPessoa', {
                id_pessoa: company.id_pessoa,
                nome: company.nome_pessoa,
                email: company.email,
                cpf_cnpj: company.cpf_cnpj,
                id_empresa: company.id_empresa,
                id_matriz: company.id_matriz,
                plano: company.plano,
                longitude: location.longitude || null,
                latitude: location.latitude || null,
                trial: company.trial,
            });

            localStorage.setItem('@notbitis', JSON.stringify(data.notifications));

            if (company.plano === 'BITIS_NOTAS') {
                window.location.replace('/app/fiscal/notas-avulsas/nova-nota');
            } else {
                window.location.replace('/app/vendas/nova-venda');
            }
        } catch (e) {
            window.console.log(e);

            if (e.response.data.message) {
                state.errors = e.response.data.errors;

                return commit('authenticationError', {
                    level: MESSAGE_LEVEL.ERROR,
                    message: e.response.data.message,
                });
            }

            return router.push({path: '/login'});
        }
    },
};
